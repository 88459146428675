import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMarques() {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/bo/Marque')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchModeles(ctx, marqueId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Modele/marque/${marqueId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
