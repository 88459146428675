import axios from 'axios'

class MarqueService {
  static initMarques() {
    return axios.get('/v1/bo/Marque')
  }

  static deleteMarque(marqueId) {
    return axios.delete(`/v1/bo/Marque/${marqueId}`)
  }

  static addMarque(formMarque) {
    return axios.post('/v1/bo/Marque', formMarque)
  }

  static updateMarque(marqueId, formMarque) {
    return axios.put(`/v1/bo/Marque/${marqueId}`, formMarque)
  }

  static fetchMarqueById(marqueId) {
    return axios.get(`/v1/bo/Marque/${marqueId}`)
  }

  static initModeles(marqueId) {
    return axios.get(`/v1/bo/Modele/marque/${marqueId}`)
  }

  static addModele(marqueId, formModele) {
    return axios.post(`/v1/bo/Modele/marque/${marqueId}`, formModele)
  }

  static updateModele(modeleId, formModele) {
    return axios.patch(`/v1/bo/Modele/${modeleId}`, formModele)
  }

  static deleteModele(modeleId) {
    return axios.delete(`/v1/bo/Modele/${modeleId}`)
  }

  static fetchModeleById(modeleId) {
    return axios.get(`/v1/bo/Modele/${modeleId}`)
  }
}

export default MarqueService
