import FacturationService from '@/services/facturation.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    initFacturation(ctx, { clientId, queryParams }) {
      return FacturationService.initFacturation(clientId, queryParams)
    },
    exportFacturation(ctx, { clientId, queryParams }) {
      return FacturationService.exportFacturation(clientId, queryParams)
    },
  },
}
