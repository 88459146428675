export const initialAbility = [
  {
    action: 'read',
    subject: 'Public',
  },
]

export const adminAbility = [
  {
    action: 'manage',
    subject: 'all',
  },
]

export const backOfficeAbility = [
  {
    action: 'read',
    subject: 'all',
  },
  {
    action: 'manage',
    subject: 'Vehicule',
  },
  {
    action: 'manage',
    inverted: true,
    subject: 'Statistique',
  },
]

export const frontOfficeAbility = [
  {
    action: 'read',
    subject: 'Public',
  },
  {
    action: ['read', 'update'],
    subject: 'Vehicule',
  },
  {
    action: 'read',
    subject: 'Statistique',
  },
]

export const _ = undefined
