import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSites(ctx, clientId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Site/client/${clientId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFrontOfficeSites() {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/fo/Site')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSite(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Site/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSite(ctx, { clientId, siteData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/v1/bo/Site/client/${clientId}`, siteData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSite(ctx, siteData) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/v1/bo/Site/${siteData.id}`, siteData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSite(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/bo/Site/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
