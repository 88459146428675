import firebase from 'firebase'
import updateUserAbility from '@/libs/acl/updateUserAbility'
import store from '../store'

const configOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
}

export default function initFirebase() {
  firebase.initializeApp(configOptions)

  firebase.auth().onAuthStateChanged(user => {
    store.dispatch('appAuth/fetchUser', user).then(() => {
      updateUserAbility(store.state.user)
    })
  })

  // add this method because firebase.auth().currentUser do not return a promise and check for local user only.
  firebase.getCurrentUser = () => new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe()
      store.dispatch('appAuth/fetchUser', user).then(() => {
        updateUserAbility(store.state.user)
        resolve(user)
      })
    }, reject)
  })
}
