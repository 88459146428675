import MarqueService from '@/services/marque.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    initMarques() {
      return MarqueService.initMarques()
    },
    deleteMarque(ctx, modeleId) {
      return MarqueService.deleteMarque(modeleId)
    },
    addMarque(ctx, formMarqueValue) {
      return MarqueService.addMarque(formMarqueValue)
    },
    updateMarque(ctx, { marqueId, formMarqueValue }) {
      return MarqueService.updateMarque(marqueId, formMarqueValue)
    },
    fetchMarqueById(ctx, marqueId) {
      return MarqueService.fetchMarqueById(marqueId)
    },
    initModeles(ctx, marqueId) {
      return MarqueService.initModeles(marqueId)
    },
    addModele(ctx, { marqueId, formModeleValue }) {
      return MarqueService.addModele(marqueId, formModeleValue)
    },
    updateModele(ctx, { modeleId, formModeleValue }) {
      return MarqueService.updateModele(modeleId, formModeleValue)
    },
    deleteModele(ctx, modeleId) {
      return MarqueService.deleteModele(modeleId)
    },
    fetchModeleById(ctx, modeleId) {
      return MarqueService.fetchModeleById(modeleId)
    },
  },
}
