import axios from 'axios'

class FacturationService {
  static initFacturation(clientId, queryParams) {
    return axios.get(`/v1/bo/Facturation/${clientId}`, { params: queryParams })
  }

  static exportFacturation(clientId, queryParams) {
    return axios.get(`/v1/bo/Facturation/export/${clientId}`, { params: queryParams })
  }
}

export default FacturationService
