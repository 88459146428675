import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import clientStoreModule from '@/views/client/clientStoreModule'
import siteStoreModule from '@/views/site/siteStoreModule'
import receptionnaireStoreModule from '@/views/receptionnaire/receptionnaireStoreModule'
import vehiculeStoreModule from '@/views/vehicule/vehiculeStoreModule'
import modeleStoreModule from '@/views/modele/modeleStoreModule'
import usersStoreModule from '@/views/users/usersStoreModule'
import modelesStoreModule from '@/views/modeles/modelesStoreModule'
import statistiqueStoreModule from '@/views/statistiques/statistiqueStoreModule'
import destockageStoreModule from '@/views/destockage/destockageStoreModule'
import app from './app'
import appConfig from './app-config'
import appAuth from './app-auth'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    appAuth,
    verticalMenu,
    client: clientStoreModule,
    site: siteStoreModule,
    receptionnaire: receptionnaireStoreModule,
    vehicule: vehiculeStoreModule,
    modele: modeleStoreModule,
    users: usersStoreModule,
    modeles: modelesStoreModule,
    statistique: statistiqueStoreModule,
    destockage: destockageStoreModule,
  },
  strict: process.env.DEV,
})
