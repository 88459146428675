import StatistiqueService from '@/services/statistiques.services'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllStats() {
      return StatistiqueService.fetchAllStats()
    },
    fetchStatsFromClient(ctx, clientId) {
      return StatistiqueService.fetchStatsFromClient(clientId)
    },
    fetchStatsFromFrontOfficeUser() {
      return StatistiqueService.fetchStatsFromFrontOfficeUser()
    },
  },
}
