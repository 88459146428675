import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVehicules(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/fo/Vehicule', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVehiculesByClient(ctx, { clientId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Vehicule/client/${clientId}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportVehicules(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/fo/Vehicule/export', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportVehiculesByClient(ctx, { clientId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Vehicule/export/client/${clientId}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVehicule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Vehicule/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFrontOfficeVehicule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/fo/Vehicule/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addVehicule(ctx, vehiculeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/bo/Vehicule', vehiculeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVehicule(ctx, vehiculeData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/bo/Vehicule/${vehiculeData.id}`, vehiculeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFrontOfficeVehicule(ctx, vehiculeData) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/v1/fo/Vehicule/demande-sortie/${vehiculeData.id}`, vehiculeData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFrontOfficeVehicule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/fo/Vehicule/demande-sortie/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVehicule(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/bo/Vehicule/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEtiquette() {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/bo/Etiquette')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEtiquetteByVehicule(ctx, vehiculeId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Etiquette/${vehiculeId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchVehiculesByClientAndNumeroSerie(ctx, { clientId, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Vehicule/client/${clientId}/search`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchVehiculesByNumeroSerie(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/fo/Vehicule/search', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
