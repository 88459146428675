export default [
  // *===============================================---*
  // *--------- Vehicule ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/vehicules/stock',
    name: 'vehicules-stock',
    component: () => import('@/views/vehicule/vehicules-list/VehiculesListStock'),
    meta: {
      resource: 'Vehicule',
      action: 'read',
    },
  },
  {
    path: '/vehicules/atente-destockage',
    name: 'vehicules-attente-destockage',
    component: () => import('@/views/vehicule/vehicules-list/VehiculesListAttenteDestockage'),
    meta: {
      resource: 'Vehicule',
      action: 'read',
    },
  },
  {
    path: '/vehicules/sortis',
    name: 'vehicules-sortis',
    component: () => import('@/views/vehicule/vehicules-list/VehiculesListSortis'),
    meta: {
      resource: 'Vehicule',
      action: 'read',
    },
  },
  {
    path: '/vehicules/add',
    name: 'add-vehicule',
    component: () => import('@/views/vehicule/AddVehicule'),
    meta: {
      resource: 'Vehicule',
      action: 'create',
    },
  },
  {
    path: '/vehicules/edit/:id',
    name: 'update-vehicule',
    component: () => import('@/views/vehicule/UpdateVehicule'),
    meta: {
      resource: 'Vehicule',
      action: 'update',
    },
  },
  {
    path: '/vehicules/search',
    name: 'vehicules-search',
    component: () => import('@/views/vehicule/VehiculesSearch'),
    meta: {
      resource: 'Vehicule',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Statistiques ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/statistiques/list',
    name: 'statistiques-list',
    component: () => import('@/views/statistiques/statistiques-list/StatistiquesList'),
    meta: {
      resource: 'Statistique',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- Destockage ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/destockage/list',
    name: 'destockage-list',
    component: () => import('@/views/destockage/destockage-list/DestockageList'),
    meta: {
      resource: 'Destockage',
      action: 'read',
    },
  },
]
