<template>
  <div class="vld-parent">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="fullPage"
    />
  </div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    Loading,
  },
  data() {
    return {
      fullPage: true,
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
  },
}
</script>
