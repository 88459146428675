import UserService from '@/services/user.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    initUsers() {
      return UserService.initUsers()
    },
    deleteUser(ctx, userId) {
      return UserService.deleteUser(userId)
    },
    addUser(ctx, formUserValue) {
      const userData = { ...formUserValue }
      if (userData.utilisateurRole !== 'frontoffice') {
        delete userData.siteId
        delete userData.clientId
      }
      return UserService.addUser(userData)
    },
    updateUser(ctx, { userId, formUserValue }) {
      const userData = { ...formUserValue }
      if (userData.utilisateurRole !== 'frontoffice') {
        delete userData.siteId
        delete userData.clientId
      }
      return UserService.updateUser(userId, userData)
    },
    fetchUserById(ctx, userId) {
      return UserService.fetchUserById(userId)
    },
  },
}
