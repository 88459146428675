import axios from 'axios'

class StatistiqueService {
  static fetchAllStats() {
    return axios.get('/v1/bo/Statistique')
  }

  static fetchStatsFromClient(clientId) {
    return axios.get(`/v1/bo/Statistique/${clientId}`)
  }

  static fetchStatsFromFrontOfficeUser() {
    return axios.get('/v1/fo/Statistique')
  }
}

export default StatistiqueService
