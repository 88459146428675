import axios from 'axios'
import store from '@/store'
import firebase from 'firebase'
import router from '@/router'

export default function initAxios() {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.timeout = 30000

  axios.interceptors.request.use(async config => {
    store.commit('app/INCREMENT_NB_REQUESTS')

    const { token } = store.state.appAuth.user

    const configWithheaders = {
      ...config,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
    return configWithheaders
  })

  axios.interceptors.response.use(
    response => {
      store.commit('app/DECREMENT_NB_REQUESTS')

      return response
    },
    error => {
      store.commit('app/DECREMENT_NB_REQUESTS')
      if (error.response.status === 401) {
        firebase
          .auth()
          .signOut()
        router.push({ name: 'auth-login' })
      } else if (error.response.status === 409) {
        const exception = { status: error.response.status }
        throw exception
      }
      return Promise.reject(error)
    },
  )
}
