import firebase from 'firebase'
import store from '../store'

export const isUserLoggedIn = () => store.state.appAuth.user.loggedIn

export const getUserData = () => store.state.appAuth.user.data

export const isFirebaseUserLoggedIn = async () => {
  const user = await firebase.getCurrentUser()
  return !!user
}
