export default [

  // *===============================================---*
  // *--------- CLIENT ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/clients/list',
    name: 'clients-list',
    component: () => import('@/views/client/clients-list/ClientsList'),
    meta: {
      resource: 'Client',
      action: 'read',
    },
  },
  {
    path: '/clients/add',
    name: 'add-client',
    component: () => import('@/views/client/AddClient'),
    meta: {
      resource: 'Client',
      action: 'read',
    },
  },
  {
    path: '/clients/edit/:id',
    name: 'update-client',
    component: () => import('@/views/client/UpdateClient'),
    meta: {
      resource: 'Client',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- SITE ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/sites/list',
    name: 'sites-list',
    component: () => import('@/views/site/sites-list/SitesList'),
    meta: {
      resource: 'Site',
      action: 'read',
    },
  },
  {
    path: '/sites/add',
    name: 'add-site',
    component: () => import('@/views/site/AddSite'),
    meta: {
      resource: 'Site',
      action: 'read',
    },
  },
  {
    path: '/sites/edit/:id',
    name: 'update-site',
    component: () => import('@/views/site/UpdateSite'),
    meta: {
      resource: 'Site',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- Receptionnaire ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/receptionnaires/list',
    name: 'receptionnaires-list',
    component: () => import('@/views/receptionnaire/receptionnaires-list/ReceptionnairesList'),
    meta: {
      resource: 'Receptionnaire',
      action: 'read',
    },
  },
  {
    path: '/receptionnaires/add',
    name: 'add-receptionnaire',
    component: () => import('@/views/receptionnaire/AddReceptionnaire'),
    meta: {
      resource: 'Receptionnaire',
      action: 'read',
    },
  },
  {
    path: '/receptionnaires/edit/:id',
    name: 'update-receptionnaire',
    component: () => import('@/views/receptionnaire/UpdateReceptionnaire'),
    meta: {
      resource: 'Receptionnaire',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- Utilisateurs ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/users/user-list/UsersList'),
    meta: {
      resource: 'Utilisateur',
      action: 'read',
    },
  },
  {
    path: '/users/add',
    name: 'add-user',
    component: () => import('@/views/users/AddUser'),
    meta: {
      resource: 'Utilisateur',
      action: 'read',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'update-user',
    component: () => import('@/views/users/UpdateUser'),
    meta: {
      resource: 'Utilisateur',
      action: 'read',
    },
  },
  // *===============================================---*
  // *--------- Modèles véhicules ---- ---------------------------------------*
  // *===============================================---*

  {
    path: '/marques/list',
    name: 'marques-list',
    component: () => import('@/views/modeles/marques-list/MarquesList'),
    meta: {
      resource: 'Modele',
      action: 'read',
    },
  },
  {
    path: '/marques/add',
    name: 'add-marque',
    component: () => import('@/views/modeles/AddMarque'),
    meta: {
      resource: 'Modele',
      action: 'read',
    },
  },
  {
    path: '/marques/edit/:id',
    name: 'update-marque',
    component: () => import('@/views/modeles/UpdateMarque'),
    meta: {
      resource: 'Modele',
      action: 'read',
    },
  },
  {
    path: '/modeles/list/',
    name: 'modeles-list',
    component: () => import('@/views/modeles/modeles-list/ModelesList'),
    meta: {
      resource: 'Modele',
      action: 'read',
    },
  },
  {
    path: '/modeles/add/:marqueId',
    name: 'add-modele',
    component: () => import('@/views/modeles/AddModele'),
    meta: {
      resource: 'Modele',
      action: 'read',
    },
  },
  {
    path: '/modeles/update/:modeleId',
    name: 'update-modele',
    component: () => import('@/views/modeles/UpdateModele'),
    meta: {
      resource: 'Modele',
      action: 'read',
    },
  },

]
