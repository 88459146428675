import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/bo/Client')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClient(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Client/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/bo/Client', clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateClient(ctx, clientData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/bo/Client/${clientData.id}`, clientData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteClient(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/bo/Client/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
