import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReceptionnaires() {
      return new Promise((resolve, reject) => {
        axios
          .get('/v1/bo/Receptionnaire')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReceptionnaire(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/bo/Receptionnaire/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReceptionnaire(ctx, receptionnaireData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/v1/bo/Receptionnaire', receptionnaireData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReceptionnaire(ctx, receptionnaireData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/v1/bo/Receptionnaire/${receptionnaireData.id}`, receptionnaireData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReceptionnaire(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/v1/bo/Receptionnaire/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
