const SET_LOGGED_IN = 'Set logged in'
const SET_USER = 'Set user'
const SET_USER_TOKEN = 'Set user token'

export default {
  namespaced: true,
  state: {
    user: { loggedIn: false, data: {}, token: '' },
  },
  getters: {
    user: state => state.user,
  },
  mutations: {
    [SET_LOGGED_IN](state, value) {
      state.user.loggedIn = value
    },
    [SET_USER](state, data) {
      state.user.data = data
    },
    [SET_USER_TOKEN](state, data) {
      state.user.token = data.token
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      return new Promise(resolve => {
        commit(SET_LOGGED_IN, user !== null)

        if (user) {
          user.getIdTokenResult().then(idTokenResult => {
            commit(SET_USER, {
              displayName: user.displayName,
              email: user.email,
              role: idTokenResult.claims.user_role,
            })
            commit(SET_USER_TOKEN, {
              token: idTokenResult.token,
            })
          })
        }
        resolve()
      })
    },
  },
}
