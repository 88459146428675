import { isUserLoggedIn, getUserData } from '@/auth/utils'
import ability from './ability'
import {
  initialAbility, adminAbility, backOfficeAbility, frontOfficeAbility,
} from './config'

const ROLE = {
  ADMIN: 'admin',
  BACK_OFFICE: 'backoffice',
  FRONT_OFFICE: 'frontoffice',
}

const updateUserAbility = () => {
  if (!isUserLoggedIn()) {
    ability.update(initialAbility)
    return
  }

  const userRole = getUserData().role

  let userAbility

  switch (userRole) {
    case ROLE.ADMIN:
      userAbility = adminAbility
      break
    case ROLE.BACK_OFFICE:
      userAbility = backOfficeAbility
      break
    case ROLE.FRONT_OFFICE:
      userAbility = frontOfficeAbility
      break
    default:
      userAbility = initialAbility
  }

  ability.update(userAbility)
}

export default updateUserAbility
