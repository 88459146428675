import axios from 'axios'

class UserService {
  static initUsers() {
    return axios.get('/v1/bo/Utilisateur')
  }

  static deleteUser(uid) {
    return axios.delete(`/v1/bo/Utilisateur/${uid}`)
  }

  static addUser(formUser) {
    return axios.post('/v1/bo/Utilisateur', formUser)
  }

  static updateUser(userId, formUser) {
    return axios.put(`/v1/bo/Utilisateur/${userId}`, formUser)
  }

  static fetchUserById(userId) {
    return axios.get(`/v1/bo/Utilisateur/${userId}`)
  }
}

export default UserService
